.image_picker .responsive .thumbnail {
  width: 40px !important;
  height: 40px !important;
  object-fit: scale-down !important;
}

.image_picker .selected .checked .icon {
  width: 30px !important;
  height: 30px !important;
}
